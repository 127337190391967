import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {Row} from '@/_new-code/products/flexible-web-toolkit/layout/row'
import type {
	BgColor,
	MobileStackOrder,
	RowWidthType,
} from '@/_new-code/products/flexible-web-toolkit/layout/layout-common-types'
import {getContainerClass} from '@/components/BlockMapper/utils'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {getBrandColor} from '../helpers'
import {spacingPadding} from '../../styles'

export type RowContentItem = IContentItem<{
	items: Elements.LinkedItemsElement
	rowType: Elements.MultipleChoiceElement
	snippetContainerResize: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	snippetLayoutBackgroundColorColor: Elements.MultipleChoiceElement
	mobileStackOrder: Elements.MultipleChoiceElement
}>

export const RowBlock: Block<RowContentItem> = ({
	block,
	BlockMapper,
	page,
	...context
}) => {
	const rowWidthType: RowWidthType = (() => {
		switch (block.elements.rowType[0]?.codename) {
			case 'full_width': {
				return 'full-width'
			}
			case 'n50_50_split': {
				return 'fifty-fifty'
			}
			case 'n70_30_split': {
				return 'seventy-thirty'
			}
			case 'n30_70_split': {
				return 'thirty-seventy'
			}
			default: {
				return 'full-width'
			}
		}
	})()

	const containerSize = getContainerClass(
		block.elements.snippetContainerResize[0]?.codename ?? ''
	)

	const componentSpacing = (() => {
		switch (block.elements.snippetSpacingSpacing[0]?.codename) {
			case 'default': {
				return spacingPadding.default
			}
			case 'top': {
				return spacingPadding.top
			}
			case 'bottom': {
				return spacingPadding.bottom
			}
			case 'none': {
				return spacingPadding.none
			}
			default: {
				return spacingPadding.none
			}
		}
	})()

	const brand = page.elements.config[0]
		? getBrandColor(page.elements.config[0])
		: null

	const snippetColor: BgColor = (() => {
		const codename =
			block.elements.snippetLayoutBackgroundColorColor[0]?.codename
		if (codename) {
			return codename as BgColor
		}
		return 'none'
	})()
	const liteBackground =
		snippetColor === 'brand'
			? ` !bg-${brand}-light bg-default-light-background`
			: ''

	const mobileStackOrder: MobileStackOrder = (() => {
		const codename = block.elements.mobileStackOrder[0]?.codename
		if (codename) {
			return codename as MobileStackOrder
		}
		return 'default'
	})()

	return (
		<Row
			bgColor={snippetColor}
			className={clsx(componentSpacing, liteBackground)}
			containerClassName={containerSize}
			mobileStackOrder={mobileStackOrder}
			rowWidthType={rowWidthType}
		>
			{block.elements.items.map((eachBlock, key) => {
				return (
					<div
						className={clsx(rowWidthType.split('-')[key], 'w-full')}
						key={eachBlock.system.id}
					>
						<BlockMapper
							blocks={[eachBlock]}
							className="not-italic"
							page={page}
							{...context}
						/>
					</div>
				)
			})}
		</Row>
	)
}
