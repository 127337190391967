import {clsx} from 'clsx'
import {backgroundThemes} from '../colors/themes'
import type {BgColor, ContainerClassName} from './layout-common-types'

interface ColumnProps {
	className?: string
	bgColor?: BgColor
	containerClassName?: ContainerClassName
	children?: React.ReactNode
}

export const Column = ({
	bgColor = 'none',
	children = null,
	className = '',
	containerClassName = 'container-wide',
}: ColumnProps): JSX.Element => {
	return (
		<div className={clsx(className, backgroundThemes.get(bgColor))}>
			<div
				className={clsx(
					containerClassName,
					containerClassName === 'container' && 'px-4 md:px-0',
					`grid auto-cols-auto grid-flow-row auto-rows-min grid-cols-1 place-items-center md:grid-flow-row md:grid-cols-1`
				)}
			>
				{children}
			</div>
		</div>
	)
}
