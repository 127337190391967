import {clsx} from 'clsx'
import styles from '@/_new-code/products/flexible-web-toolkit/layout/row.module.css'
import {backgroundThemes} from '../colors/themes'
import type {
	BgColor,
	ContainerClassName,
	RowWidthType,
	MobileStackOrder,
} from './layout-common-types'

interface RowProps {
	className?: string
	bgColor?: BgColor
	containerClassName?: ContainerClassName
	rowWidthType?: RowWidthType
	mobileStackOrder?: MobileStackOrder
	children?: React.ReactNode
}

export const Row = ({
	className = '',
	bgColor = 'none',
	containerClassName = 'container-wide',
	rowWidthType = 'full-width',
	children = null,
	mobileStackOrder = 'default',
}: RowProps): JSX.Element => {
	return (
		<div className={clsx(className, backgroundThemes.get(bgColor))}>
			<div
				className={clsx(
					containerClassName,
					styles[rowWidthType],
					clsx(
						mobileStackOrder === 'reverse'
							? `${styles['flex-reverse']}`
							: ``
					)
				)}
			>
				{children}
			</div>
		</div>
	)
}
